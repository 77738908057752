export default function permissions(app) {
  // 编辑页面的按钮权限   有权限
  app.directive('has', {
    mounted(el, binding) {
      if (!permsJudge(binding.value)) {
        el.parentNode.removeChild(el);
      }
      function permsJudge(value) {
        let perms = sessionStorage.getItem("userPermissions") && (JSON.parse(sessionStorage.getItem("userPermissions")) || []) || [];
        // 优化成indexOf  或者 some 
        // return perms.some(item => item == value);
        for (let item of perms) {
          if (item === value) {
            return true;
          }
        }
        return false;
      }
    }
  });
  // 编辑页面的按钮权限   没有权限
  app.directive('noHas', {
    mounted(el, binding) {
      if (permsJudge(binding.value)) {
        el.parentNode.removeChild(el);
      }
      function permsJudge(value) {
        let perms = sessionStorage.getItem("userPermissions") && (JSON.parse(sessionStorage.getItem("userPermissions")) || []) || [];
        for (let item of perms) {
          if (item === value) {
            return true;
          }
        }
        return false;
      }
    }
  });
  // 查看页面的操作权限 - 有权限
  app.directive('userOpHas', {
    mounted(el, binding) {
      if (!permsJudge(binding.value)) {
        el.parentNode.removeChild(el);
      }
      function permsJudge(value) {
        let perms = sessionStorage.getItem("userOpPermissions") && (JSON.parse(sessionStorage.getItem("userOpPermissions")) || []) || [];
        for (let item of perms) {
          if (item === value) {
            return true;
          }
        }
        return false;
      }
    }
  });
  // 查看页面的操作权限 - 没有权限
  app.directive('noUserOpHas', {
    mounted(el, binding) {
      if (permsJudge(binding.value)) {
        el.parentNode.removeChild(el);
      }
      function permsJudge(value) {
        let perms = sessionStorage.getItem("userOpPermissions") && (JSON.parse(sessionStorage.getItem("userOpPermissions")) || []) || [];
        for (let item of perms) {
          if (item === value) {
            return true;
          }
        }
        return false;
      }
    }
  });
}
