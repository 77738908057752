const __pages_import_0__ = () => import("/src/views/preview/menus/index.vue");
const __pages_import_1__ = () => import("/src/views/view/index.vue");
const __pages_import_2__ = () => import("/src/views/test/index.vue");
const __pages_import_3__ = () => import("/src/views/szy/index.vue");
const __pages_import_4__ = () => import("/src/views/preview/index.vue");
const __pages_import_5__ = () => import("/src/views/info/index.vue");
const __pages_import_6__ = () => import("/src/views/edit/index.vue");

const routes = [{"name":"preview-menus","path":"/preview/menus","component":__pages_import_0__,"props":true},{"name":"view","path":"/view","component":__pages_import_1__,"props":true},{"name":"test","path":"/test","component":__pages_import_2__,"props":true},{"name":"szy","path":"/szy","component":__pages_import_3__,"props":true},{"name":"preview","path":"/preview","component":__pages_import_4__,"props":true},{"name":"info","path":"/info","component":__pages_import_5__,"props":true},{"name":"edit","path":"/edit","component":__pages_import_6__,"props":true}];

export default routes;