import { createPinia } from 'pinia';
import { useAppStore } from './modules/app';
import { useUserStore } from './modules/user';
import { useSzyStore } from './modules/szy';
import { useObjectsColorStore } from './modules/objects-color'

import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

export { useAppStore, useUserStore,useSzyStore,useObjectsColorStore };
export default pinia;
