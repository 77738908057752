import axios from 'axios';
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError, InternalAxiosRequestConfig } from 'axios';
import { showMessage } from './status';
import { IResponse } from './type';
import { getToken } from '/@/utils/auth';
import { message } from 'ant-design-vue';
import { useAppOutsideStore } from '/@/store/modules/app/index';
import { storeToRefs } from 'pinia';
const useAppStore = useAppOutsideStore();
const VITE_API_BASEURL = import.meta.env.VITE_API_BASEURL
const service: AxiosInstance = axios.create({
  baseURL: VITE_API_BASEURL,
  timeout: 60000,
});

// axios实例拦截请求
service.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    try {
      let { token: storeToken } = storeToRefs(useAppStore)
      const token: string | unknown = await getToken() || storeToken.value || localStorage.getItem('TOKEN');
      if(config.withXSRFToken){
        config.headers.Authorization = localStorage.getItem('TOKEN__');
      }else{
        if (token) {
          config.headers.Authorization = token;
        }
      }
      
    } catch (error) {
      console.error(error);
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);
// service.interceptors.request.use(
//   (config: InternalAxiosRequestConfig) => {
//     // const token = getToken();
//     const token = getToken();
//     if (token) {
//       config.headers.Authorization = token
//     }
//     return config;
//   },
//   (error: AxiosError) => {
//     return Promise.reject(error);
//   },
// );

// axios实例拦截响应
service.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.status === 200) {
      return response;
    }
    showMessage(response.status);
    return response;
  },
  // 请求失败
  (error: any) => {
    console.log('error--------', error)
    const { response } = error;
    if (response) {
      // 马 2023年8月15号 要求更换调整
      if (response.status == 401 || (response.status == 400 && response.data.code == 401)) {
        message.error(response.data.msg);
        setTimeout(() => {
          // 统一跳转到社区页面
          window.location.href = `${window.location.origin}/bim-community/home`
        }, 2000)
      } else {
        message.error(response.data.msg);
      }
      // 请求已发出，但是不在2xx的范围
      return Promise.reject(response.data);
    }
    showMessage('网络连接异常,请稍后再试!');
  },
);
let pending: any = [];
const request = <T = any>(config: AxiosRequestConfig): Promise<T> => {
  let CancelToken = axios.CancelToken
  const conf = {
    ...config,
    cancelToken: new CancelToken(function (c) {
      pending.push(c)
    })
  }
  console.log('config-----', conf)
  return new Promise((resolve, reject) => {
    service.request<any, AxiosResponse<IResponse>>(conf)
      .then((res: AxiosResponse<IResponse>) => {
        console.log('res--------', res)
        const { data } = res;
        if (data.code !== 200) {
          reject(data);
        } else {
          resolve(data as unknown as T);
        }
      })
      .catch((error: AxiosError) => {
        if (error.response && error.response.status === 400) {
          // 这里处理接口返回 400 错误
          // 例如，你可以通过 error.response.data 获取服务器返回的错误信息
          const errorMessage = error.response.data;
          reject(errorMessage);
        } else {
          // 处理其他类型的错误
          reject(error);
        }
      });
  });
};

export function get<T = any>(config: AxiosRequestConfig): Promise<T> {
  return request({ ...config, method: 'GET' });
}

export function post<T = any>(config: AxiosRequestConfig): Promise<T> {
  return request({ ...config, method: 'POST' });
}


export function cancelAll() {
  console.log('cancelAll-pending--------------', pending)
  for (let i = 0; i < pending.length; i++) {
    const item: any = pending[i];
    item.source();
    pending.splice(i, 1);
  }
}

export default request;

export type { AxiosInstance, AxiosResponse, };
