<template>
  <a-config-provider :locale="locale">
    <router-view id="app" />
  </a-config-provider>
</template>

<script setup lang="ts">
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
const locale = ref();
locale.value = zhCN;
onErrorCaptured(err => {
  console.error(err)
});
</script>

<style>
html,body {
  width: 100%;
  height: 100%;
}
#app {
  height: 100%;
  width: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: black; */
  background: url('https://zsw-bimface-res2.izsw.net/img/big-banner.png') center/cover no-repeat !important;
}

@font-face {
  font-family: TitleFont;
  src: url('./assets/fonts/titleFont/ttf.ttf');
  font-weight: normal;
  font-style: normal;
}
.bf-info .bf-info-power {
  visibility: hidden !important;
}
</style>
