import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import routes from 'virtual:generated-pages';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { baseGetPermissions } from '/@/api/auth';
import { Base64 } from 'js-base64';
import { useAppOutsideStore } from '/@/store/modules/app/index';
const useAppStore = useAppOutsideStore();
import { storeToRefs } from 'pinia';
import isEmpty from 'lodash/isEmpty';
routes.push({
  path: '/',
  redirect: () => {
    window.location.href= '/bim-community/home'
  }
});
console.log('routes:', routes)
//导入生成的路由数据
const router = createRouter({
  history: createWebHashHistory('web'),
  routes,
});
router.beforeEach(async (_to, _from, next) => {
  // 没有参数跳转到社区页
const {query} = _to;
if (isEmpty(query)) {
  window.location.href= '/bim-community/home'
  next(false)
}
  console.log('_to1111', _to)
  // sessionStorage.setItem('userPermissions', JSON.stringify(userPermissions.value));
  NProgress.start();
  let userPermissions = [];
  if (_to.query.op) {
    userPermissions = _to.query.op.split(',');
    sessionStorage.setItem('userPermissions', JSON.stringify(userPermissions));
  }
  if (_to.query.token) {
    console.log('_to.query.token', _to, _to.query);
    let queryToken = Base64.decode(decodeURIComponent(_to.query.token));
    localStorage.setItem('TOKEN', queryToken);
    console.log('Base64.decode(decodeURIComponent(_to.query.token))', queryToken);
    let { token } = storeToRefs(useAppStore);
    token.value = queryToken;
    console.log('token.value-queryToken', queryToken, 'token', token, token.value);
    // 根据token获取用户权限
    let { data } = await baseGetPermissions();
    sessionStorage.setItem('userPermissions', JSON.stringify(data));
    if (_to.query.op) {
      let userOpPermissions = _to.query.op.split(',')
      sessionStorage.setItem('userOpPermissions', JSON.stringify(userOpPermissions));
    } else {
      sessionStorage.setItem('userOpPermissions', '');
    }
    console.log(',.,,.')
  }
  if (_to.fullPath == '/' && _from.fullPath == "/") {
    next({
      path: '/edit',
    });
    return
  }
  next();
});

router.afterEach((_to) => {
  NProgress.done();
});

export default router;
