import { defineStore } from 'pinia';
import { ObjectsColorState } from './types';

export const useObjectsColorStore = defineStore('ObjectsColor', {
  state: (): ObjectsColorState => ({
    objectsColorInfo: []
  }),
  actions: {
    // 重置用户信息
    resetColorInfo() {
      this.$reset();
    },
  }
})