const TokenKey = 'TOKEN';
const TokenPrefix = 'Bearer ';
const isLogin = () => {
  return !!localStorage.getItem(TokenKey);
};
// 获取当前页面的URL
const currentURL = window.location.href;
// 使用URLSearchParams来解析URL参数
const urlParams = new URLSearchParams(currentURL);
// 获取名为token的参数值
const tokenParam = urlParams.get('token') || localStorage.getItem('token');
const getToken = () => {
  return new Promise((resolve, reject) => {
    if (tokenParam) {
      // 解码URI组件和Base64
      try {
        let decodedToken = decodeURIComponent(tokenParam);
        const decodedBase64 = atob(decodedToken);
        // 现在decodedBase64包含了解码后的数据
        console.log(decodedBase64);
        resolve(decodedBase64)
      } catch (error) {
        console.error('无token:', error);
        reject('无token: ' + error);
      }
    } else {
      resolve('')
    }
  })
}
// const getToken = () => {
//   console.log('localStorage.getItem(TokenKey):', localStorage.getItem(TokenKey))
//   return localStorage.getItem(TokenKey);
// };
const setToken = (token: string) => {
  localStorage.setItem(TokenKey, token);
};
const clearToken = () => {
  localStorage.removeItem(TokenKey);
};
export { TokenPrefix, isLogin, getToken, setToken, clearToken };
