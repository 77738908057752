// 权限问题后期增加
import { get, post } from '/@/utils/http/axios';
const VITE_APP_REMOTE_API_PREFIX = import.meta.env.VITE_REMOTE_API_PREFIX
enum URL {
  // 获取token下所有的权限
  baseGetPermissions = `${VITE_APP_REMOTE_API_PREFIX}/editor/base/getPermissions`
}

// 获取token下所有的权限
const baseGetPermissions = async () => get({ url: `${URL.baseGetPermissions}` });

export {
  baseGetPermissions,
};
