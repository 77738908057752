
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import piniaStore from './store';
import drag from "v-drag"
import axios from 'axios'
import 'ant-design-vue/dist/antd.css';
import 'ant-design-vue/dist/antd.dark.css';
import '/@/assets/fonts/iconfont.css'
import '/@/styles/commom.less';
import 'virtual:uno.css'
import 'animate.css';
import { message } from 'ant-design-vue';
import moment from 'moment'
//按钮权限控制方法
import permissions from "./utils/permissions";
import Directives from "./utils/directives"
// 引入拖拽库
import Vue3DraggableResizable from 'vue3-draggable-resizable'
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css'
const app = createApp(App);
app.config.globalProperties.$Message = message
app.config.globalProperties.$moment = moment
app.config.globalProperties.$axios = axios
permissions(app)
app.use(drag);
app.use(router);
app.use(piniaStore);
app.mount('#app');
app.use(Directives);
app.use(Vue3DraggableResizable);
// 公共变量
window.viewer3D = {};
window.annotationToolbar = {};
